$dropdown-content-box-shadow-color: rgba(0, 0, 0, 0.16);
$dropdown-list-link-text-color: $base-greyish-brown;
$dropdown-list-link-text-hover-color: $primary-color;
$dropdown-list-item-border-color: $base-white-four;

// scss-lint:disable SelectorFormat
.dropdown {
  position: relative;

  &--active {
    .dropdown__trigger {
      &::before,
      & button::before,
      & a::before {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown__content {
  min-width: 214px;
  margin-top: 20px;
  padding: 8px 0 8px 16px;

  border-radius: 2px;
  background: $base-white-color;
  box-shadow: 0 2px 4px 0 $dropdown-content-box-shadow-color;

  &::after {
    position: absolute;
    top: -7px;
    left: 10px;

    content: '';

    border: {
      top: transparent;
      right: 10px solid transparent;
      bottom: 7px solid $base-white-color;
      left: 10px solid transparent;
    }
  }

  &--flip {
    position: absolute;
    right: 0;

    &::after {
      right: 10px;
      // scss-lint:disable ImportantRule
      left: auto !important;
      // scss-lint:enable ImportantRule
    }
  }
}

.dropdownList {
  margin: 0;
  padding: 0;

  list-style-type: none;

  &Item {
    border-top: 1px solid $dropdown-list-item-border-color;

    &:first-child {
      border-top: 0;
    }
  }

  &Link {
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;

    display: block;

    padding: 14px 14px 13px 0;

    cursor: pointer;
    text-decoration: none;

    color: $dropdown-list-link-text-color;

    &:hover {
      color: $dropdown-list-link-text-hover-color;
    }
  }
}
// scss-lint:enable
