$modal-content-shadow-color: rgba(0, 0, 0, 0.13);

// scss-lint:disable SelectorFormat
.ReactModal {
  &__Body--open {
    overflow: hidden;

    @include media(small mobile tablet) {
      position: fixed;

      max-height: none;
    }
  }

  &__Overlay {
    z-index: $z-modal-raised;

    opacity: 0;

    perspective: 600;

    &--after-open {
      transition: opacity 150ms ease-out;

      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    transform: scale(0.5) rotateX(-30deg);

    box-shadow: 0 19px 38px 0 $modal-content-shadow-color;

    &--after-open {
      transition: transform 150ms ease-in;
      transform: scale(1) rotateX(0deg);
    }

    &--before-close {
      transition: transform 150ms ease-in;
      transform: scale(0.5) rotateX(30deg);
    }
  }
}
// scss-lint:enable SelectorFormat
