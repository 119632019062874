$shadow-color: rgba(0, 0, 0, 0.22);
$top-border-color: $base-purple-color;
$search-result-hover-color: $base-purple-color;
$result-text-color: $base-black-color;

//scss-lint:disable all
// Styling the Google-inserted search container, overriding style values
.pac-container {
  width: 278px !important;

  border: 0;

  box-shadow: 0 1px 3px 0 $shadow-color;

  .pac-item {
    margin: 0 12px;
    padding: 12px 0;

    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 0 $shadow-color;
    }

    &:first-child {
      border-top: 2px solid $top-border-color;
    }
  }

  .pac-icon {
    display: none;
  }

  .pac-item,
  .pac-item-query {
    font-family: $font-family-sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;

    color: $result-text-color;

    &:hover,
    &:hover .pac-matched {
      background-color: $base-white-color;

      color: $search-result-hover-color;
    }

    .pack-matched:hover {
      color: $search-result-hover-color;
    }
  }

  .pac-matched {
    font-weight: inherit;
  }
}
