[class^='icon']::before,
[class*=' icon']::before {
  font-family: 'social';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1em;

  display: inline-block;

  width: 1em;
  margin-right: 0.2em;
  margin-left: 0.2em;

  text-align: center;
  text-decoration: inherit;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.iconFacebook::before {
  content: '\e800';
}

.iconTwitter::before {
  content: '\e801';
}
