$label-text-color: $base-black-color;
$autofill-input-background-color: $base-white-color;
$form-error-color: $base-red-color;

$form-warning-color: $base-orange-color;

$input-placeholder-text-color: $base-pinkish-grey-color;

/* stylelint-disable selector-no-type */

form {
  @include susy-clearfix;

  display: block;
}

label {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  color: $label-text-color;

  &.spaced {
    display: inline-block;

    margin-bottom: 10px;
  }
}

input[type='radio'] {
  display: none;

  + label {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;

    display: inline-block;

    padding-right: 20px;

    span {
      display: inline-block;

      width: 18px;
      height: 18px;
      margin: -1px 12px 0 0;

      cursor: pointer;
      vertical-align: middle;

      background: url('../../icons/input-radio.svg') left top no-repeat;
    }
  }

  &:checked {
    + label {
      span {
        background: url('../../icons/input-radio-checked.svg') left top no-repeat;
      }
    }
  }
}

select {
  background-image: url('../../icons/dropdown-arrow.svg');
  background-repeat: no-repeat;
  background-position: center right;

  &:invalid {
    color: $input-placeholder-text-color;
  }
}

fieldset {
  margin: {
    top: 0;
    right: 0;
    bottom: 35px;
    left: 0;
  }
  padding: 0;

  border: 0;
}


.error {
  color: $form-error-color;

  &--with-icon {
    margin-left: 40px;
  }

  &--image {
    font-size: 18px;

    width: 100%;
    margin-top: 8px;

    text-align: center;
  }
}

.warning {
  color: $form-warning-color;
}

.mapError {
  box-shadow: 0 0 0 2px $form-error-color;
}

.info-list {
  margin: 10px 0;
  padding-left: 20px;
  color: $base-greyish-brown;
}

.info-text {
  margin: 5px 0 0 0;
}

/* stylelint-enable */
