.u {
  &-hidden-mobile {
    @include media(small mobile) {
      display: none;
    }
  }

  &-visible-mobile {
    @include media(tablet desktop large) {
      display: none;
    }
  }
}