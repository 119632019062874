$arrow-color: $base-purple-color;
$border-separator-color: rgba($base-purple-color, 0.4);
$active-element-color: $base-purple-color;

// scss-lint:disable QualifyingElement
.rdtPrev,
.rdtNext {
  color: $arrow-color;
}

.rdtPicker {

  th {
    border-bottom-color: $border-separator-color;
  }

  td.rdtActive,
  td.rdtActive:hover {
    background-color: $active-element-color;
  }

  td.rdtToday {
    &::before {
      border-bottom-color: $active-element-color;
    }
  }
}

.rdtCounter {
  .rdtBtn {
    color: $arrow-color;
  }
}
// scss-lint:enable
