@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Thin.eot');
	src: url('../../assets/fonts/NationalWeb-Thin.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Thin.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Light.eot');
	src: url('../../assets/fonts/NationalWeb-Light.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Light.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Book.eot');
	src: url('../../assets/fonts/NationalWeb-Book.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Book.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Regular.eot');
	src: url('../../assets/fonts/NationalWeb-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Regular.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Medium.eot');
	src: url('../../assets/fonts/NationalWeb-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Medium.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Semibold.eot');
	src: url('../../assets/fonts/NationalWeb-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Semibold.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../../assets/fonts/NationalWeb-Extrabold.eot');
	src: url('../../assets/fonts/NationalWeb-Extrabold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/NationalWeb-Extrabold.woff2') format('woff2'),
		url('../../assets/fonts/NationalWeb-Extrabold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'social';
	src: url('../../assets/fonts/social.eot');
	src: url('../../assets/fonts/social.eot#iefix') format('embedded-opentype'),
		url('../../assets/fonts/social.woff2') format('woff2'), url('../../assets/fonts/social.woff') format('woff'),
		url('../../assets/fonts/social.ttf') format('truetype'), url('../../assets/fonts/social.svg#social') format('svg');
	font-weight: normal;
	font-style: normal;
}

// body {
//   font-family: 'Inter', sans-serif;
//   font-weight: 400; /* Regular */
// }

// h1, h2, h3 {
//   font-family: 'Inter', sans-serif;
//   font-weight: 700; /* Bold */
// }

// em, blockquote {
//   font-family: 'Inter', sans-serif;
//   font-weight: 400;
//   font-style: italic; /* Italic */
// }

@font-face {
  font-family: 'Noto Serif Thai';
  src: url('../../assets/fonts/NotoSerifThai-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif Thai';
  src: url('../../assets/fonts/NotoSerifThai-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Serif Thai';
  src: url('../../assets/fonts/NotoSerifThai-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../../assets/fonts/NotoSansThai-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../../assets/fonts/NotoSansThai-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../../assets/fonts/NotoSansThai-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
