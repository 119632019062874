$link-normal-color: $base-purple-color;
$link-hover-color: $base-dark-purple;

:root {
  --font-family-sans-serif: 'National';
}

html {
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: var(--font-family-sans-serif);
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
}

a {
  &.link {
    cursor: pointer;

    &,
    &:active,
    &:focus,
    &:visited {
      color: $link-normal-color;
    }

    &:hover {
      color: $link-hover-color;
    }

    &.link--no-decoration {
      text-decoration: none;
    }
  }
}

b,
strong {
  font-weight: 600;
}
