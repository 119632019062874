$overlay-color: rgba(0, 0, 0, 0.2);

// scss-lint:disable SelectorFormat
.has-overlay .gm-style-pbc + div::after {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  background-color: $overlay-color;
}

.gm-style-pbc-hack {
  top: -1;
}
// scss-lint:enable SelectorFormat
