$base-white-color: #FFFFFF;
$base-black-color: #000000;

$base-gray-darker-color: #333333;
$base-gray-lighter-color: #F2F2F2;

$base-greyish-brown: #555555;
$base-brownish-grey: #666666;
$base-warm-gray-color: #7C7C7C;
$base-warm-lighter-gray-color: #888888;
$base-pinkish-grey-color: #C2C2C2;
$base-pale-grey: #999999;
$base-white-five: #DDDDDD;
$base-white-four:#E8E8E8;
$base-white-three: #F8F8F8;

$base-purple-color: #9570C2;
$base-dark-purple: #722E99;
$base-darker-purple: #632884;
$base-light-purple-color: #B297D3;
$base-medium-purple-color: #BE4CFF;
$base-dark-purple-color: #834FAD;
$base-purple-blue-color: #4E1280;
$base-alternate-purple-color: #7847B4;
$base-green-color: #97BF6F;
$base-dark-green-color: #87AB63;
$base-orange-color: #F5A623;
$base-red-color: #D94531;

$primary-color: $base-purple-color;
$secondary-color: $base-green-color;
$ternary-color: $base-orange-color;

$primary-text-color: $base-gray-darker-color;
$secondary-text-color: $base-white-color;

$facebook-color: #3B5998;
$twitter-color: #1DA1F2;

$european-color: #003398;
